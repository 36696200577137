body {
    font-family: 'Chivo Mono', monospace;
    width: min(80%, 1300px);
    height: 100%;
    margin: auto;
    background-color: #C1C1C1;
}

h2 {
    font-size: clamp(28px, 4vw, 32px);
    width: 100%;
    border-bottom: solid 4px black;
}

ul {
    list-style: none;
    padding: 0;
    font-weight: 800;
    font-size: 20px;
    color: #595959;

    li {
        margin-bottom: 2rem;
    }

    li:before {
        content: '>> ';
    }

    p {
        font-weight: 300;
        font-size: 1rem;
        margin: 0.5rem 0 0 0;
        color: black;
    }
}

a {
    text-decoration: none;
    color: black;
}