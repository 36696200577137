.app-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h1 {
        font-size: clamp(50px, 4vw, 72px);
        font-weight: 900;
        text-shadow: 3px 3px #ffffff;
    }

    .gap {
        flex: 1 0 1vw;
    }

    .app-nav {
        display: flex;
        flex-direction: row;
        
        a {

            margin: 0 16px;
            font-weight: 800;
            border-bottom: 6px solid #C1C1C1;
            display: inherit;

            &.selected {
                border-color: #ffffff;
            }

            img {
                height: 1rem;
                width: 1rem;
            }
        }

        a:hover:not(.out-link) {
            transition: 0.5s;
            border-color: #ffffff;
        }

        a:hover.out-link {
            transition: 0.5s;
            color: #595959;
        }
    }
}

@media (max-width: 1200px) {
    .app-header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .gap {
            flex: 0 0 0;
        }

        .app-nav {
            a {
                font-size: 14px;
                margin-left: 4px;
            }

            a:first-child {
                margin-left: 0;
            }
            margin-bottom: 1rem;
        }
    }
}